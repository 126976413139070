body {
	font-size: 1em;
	line-height: 1.5em;
	font-family: $baseFont; }


a {
	color: {} }

h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
	line-height: 1.2em;
	font-family: $heading;
	letter-spacing: -0.03em;
	margin-bottom: 10px;

	a {
		font-weight: inherit !important;
		color: $deep-blue; } }


h1 {
	font-size: 1.8em;
	margin-bottom: 10px;
	font-weight: 800;
	text-transform: uppercase; }

h2 {
	font-size: 1.5em; }

h3 {
	font-size: 1.3em;
	margin-bottom: 5px; }

h4 {
	font-size: 1em;
	margin-bottom: 5px;
	color: $deep-blue; }

h5 {
	font-size: 1em;
	color: $deep-blue; }
p {
	font-size: 1em;
	margin: 0 0 20px 0;

	&:last-child {
		margin-bottom: 0; } }

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, p:last-child {
	margin-bottom: 0; }


.mainContent a {
	font-weight: 600;
	color: #035a9d; }

.mainContent a:hover {
	text-decoration: underline; }


.the_content_wrapper {
	h2 {
		color: $deep-blue; } }

.sidebar {
	h3 {
		line-height: 1.3em;
		font-size: 1.3em;
		text-transform: uppercase;
		margin-bottom: 0;
		padding: 8px 10px;
		background: $deep-blue;
		color: #fff;

		a {
			color: #fff;
			background: $deep-blue;
			line-height: 1.2em !important;
			padding: 0 !important;

			&:hover {
				background: $deep-blue !important; } } }

	&.popular-topics {
		h3 {
			background-color: $deep-blue;
			color: white; } } }


#audiences {
	h4 {
		padding: 5px 10px;
		background-color: $deep-blue;
		color: white;
		text-transform: uppercase;
		margin-bottom: 0;

		a {
			color: #fff;
			font-weight: 200;
			display: block;

			&:hover {
				text-decoration: none; } } }
	img {
		margin-bottom: 0.5em; } }


.wp-caption-text {
	font-style: italic;
	text-align: center;
	color: $deep-blue; }
