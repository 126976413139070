body.overlay-on {
	overflow: hidden; }


$awesome-green: #00ff99;

// modal close button
.pi-modal-close-button {
	$button-side-length: 36px;
	$hypotenuse-length: 50.91168824543142px;

	position: absolute;
	top: 50px;
	right: 50px;
	width: $button-side-length;
	height: $button-side-length;
	border: 0;

	&:before, &:after {
		content: "";
		position: absolute;
		top: 0;
		width: $hypotenuse-length;
		height: 2px;
		background-color: red; }

	&:before {
		left: 0;
		transform-origin: top left;
		transform: rotateZ(45deg); }

	&:after {
		right: 0;
		transform-origin: top right;
		transform: rotateZ(-45deg); } }

// modal overlay
.pi-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: white;
	background-color: rgba(0, 0, 0, 0.8);
	display: none;
	opacity: 0;
	transition: 0.3s;

	&.on {
		opacity: 1; } }

// multiFrameDisplay
.pi-multi-frame-display {
	position: relative;
	width: 90vw;
	height: 90vh;
	margin: 10vh auto;

	.item-wrapper {
		position: relative;
		width: 100%;
		height: 100%; }

	.item {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: none;

		&.on {
			display: block; } }

	.pager {
		position: absolute;
		bottom: 10%;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;

		.pager-button {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background-color: black;
			border: 0;
			margin: 0 10px;
			transition: 0.3s;

			&.yah {
				background-color: $awesome-green; } } }

	$prev-next-border-style: 1px solid;
	.prev-button, .next-button {
		position: absolute;
		top: 50%;
		width: 30px;
		height: 30px;
		border: 0;
		border-color: $awesome-green;
		z-index: 3;
		transform: translateY(-50%) rotateZ(45deg);
		transition: 0.3s;

		&:hover {
			border-color: red; } }

	.prev-button {
		left: 30px;
		border-bottom: $prev-next-border-style;
		border-left: $prev-next-border-style; }

	.next-button {
		right: 30px;
		border-top: $prev-next-border-style;
		border-right: $prev-next-border-style; } }

