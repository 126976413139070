.pi-rotator {
	width: 50vw;
	height: 50vh;

	.item-wrapper {
		position: relative;
		overflow: hidden;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%; }

	.item {
		position: absolute;
		top: auto;
		left: auto;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: none;
		transition: none;
		display: none;

		&.on, &.inFromLeft, &.inFromRight, &.outFromLeft, &.outFromRight {
			display: flex;
			opacity: 0;
			transition: opacity 0.6s, transform 0.3s; }

		&.on {
			opacity: 1;
			transform: translateX(0%); }

		&.inFromLeft {
			transform: translateX(-100%); }

		&.inFromRight {
			transform: translateX(100%); }

		&.outFromLeft {
			transform: translate(100%); }

		&.outFromRight {
			transform: translate(-100%); } }

	.image-wrapper {
		max-width: 100%;
		max-height: 100%;

		img {
			max-width: 100%;
			max-height: 100%; } }

	.prev-button, .next-button {
		border-color: black; }

	.prev-button {
		left: -30px; }

	.next-button {
		right: -30px; } }



.pi-modal-overlay {
	.pi-rotator {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		width: 100%;
		height: 100%;

		.item-wrapper {
			margin: 10%;
			width: 80%;
			height: 80%; }

		.image-wrapper {
			width: auto;
			height: auto; }


		.prev-button, .next-button {
			border-color: white; }

		.prev-button {
			left: 50px; }

		.next-button {
			right: 50px; } } }
