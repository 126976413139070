@charset "UTF-8";
#search button, .hamburger, .hamburger:before, .hamburger:after {
  transition: all 0.15s ease-out; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, code, em, img, strike, strong, sub, sup,
fieldset, figcaption, figure, form, label, q,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, figure, figcaption, footer, header,
menu, nav, section, summary, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  outline: none; }

body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none; }

blockquote, q {
  quotes: none; }

a {
  text-decoration: none;
  color: inherit; }
  a img {
    border: 0; }

p {
  margin: 0 0 20px 0; }

strong, b {
  font-weight: bold; }

hr {
  clear: both;
  height: 0;
  border: solid #ccc;
  border-width: 0 0 1px 0;
  padding-top: 10px;
  margin-bottom: 10px; }

input, textarea, button {
  padding: 0;
  border: 0;
  background: none;
  font: inherit;
  letter-spacing: inherit;
  box-sizing: border-box;
  outline: none; }

table, th, tbody, tr, td {
  border: 0; }

table {
  margin-bottom: 20px;
  clear: both;
  border-spacing: 0;
  line-height: 1.2em; }
  table td {
    padding: 5px 10px; }

inside, banner, column, figcaption, figure {
  display: block; }

clear {
  display: block;
  clear: both; }

.alignright, .alignleft, .alignmiddle, .alignnone {
  width: 100%;
  height: auto;
  float: none;
  margin: 0 0 20px 0;
  display: block; }

@font-face {
  font-family: 'DIN';
  src: url("../fonts/DIN-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/DIN-Light.otf") format("opentype"), url("../fonts/DIN-Light.woff") format("woff"), url("../fonts/DIN-Light.ttf") format("truetype"), url("../fonts/DIN-Light.svg#DIN-Light") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'DIN';
  src: url("../fonts/DIN-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/DIN-Regular.woff") format("woff"), url("../fonts/DIN-Regular.ttf") format("truetype"), url("../fonts/DIN-Regular.svg#DIN-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'DIN';
  src: url("../fonts/DIN-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/DIN-Medium.otf") format("opentype"), url("../fonts/DIN-Medium.woff") format("woff"), url("../fonts/DIN-Medium.ttf") format("truetype"), url("../fonts/DIN-Medium.svg#DIN-Medium") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'DIN';
  src: url("../fonts/DIN-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/DIN-Bold.otf") format("opentype"), url("../fonts/DIN-Bold.woff") format("woff"), url("../fonts/DIN-Bold.ttf") format("truetype"), url("../fonts/DIN-Bold.svg#DIN-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'DIN';
  src: url("../fonts/DIN-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/DIN-Black.otf") format("opentype"), url("../fonts/DIN-Black.woff") format("woff"), url("../fonts/DIN-Black.ttf") format("truetype"), url("../fonts/DIN-Black.svg#DIN-Black") format("svg");
  font-weight: 900;
  font-style: normal; }

body {
  font-size: 1em;
  line-height: 1.5em;
  font-family: "Proxima Nova", Arial, Helvetica, sans-serif; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.2em;
  font-family: "DIN", Arial, Helvetica, sans-serif;
  letter-spacing: -0.03em;
  margin-bottom: 10px; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    font-weight: inherit !important;
    color: #035a9d; }

h1 {
  font-size: 1.8em;
  margin-bottom: 10px;
  font-weight: 800;
  text-transform: uppercase; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.3em;
  margin-bottom: 5px; }

h4 {
  font-size: 1em;
  margin-bottom: 5px;
  color: #035a9d; }

h5 {
  font-size: 1em;
  color: #035a9d; }

p {
  font-size: 1em;
  margin: 0 0 20px 0; }
  p:last-child {
    margin-bottom: 0; }

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, p:last-child {
  margin-bottom: 0; }

.mainContent a {
  font-weight: 600;
  color: #035a9d; }

.mainContent a:hover {
  text-decoration: underline; }

.the_content_wrapper h2 {
  color: #035a9d; }

.sidebar h3 {
  line-height: 1.3em;
  font-size: 1.3em;
  text-transform: uppercase;
  margin-bottom: 0;
  padding: 8px 10px;
  background: #035a9d;
  color: #fff; }
  .sidebar h3 a {
    color: #fff;
    background: #035a9d;
    line-height: 1.2em !important;
    padding: 0 !important; }
    .sidebar h3 a:hover {
      background: #035a9d !important; }

.sidebar.popular-topics h3 {
  background-color: #035a9d;
  color: white; }

#audiences h4 {
  padding: 5px 10px;
  background-color: #035a9d;
  color: white;
  text-transform: uppercase;
  margin-bottom: 0; }
  #audiences h4 a {
    color: #fff;
    font-weight: 200;
    display: block; }
    #audiences h4 a:hover {
      text-decoration: none; }

#audiences img {
  margin-bottom: 0.5em; }

.wp-caption-text {
  font-style: italic;
  text-align: center;
  color: #035a9d; }

input[type=text], input[type=email], input[type=number], input[type=password], textarea, button {
  padding: 5px 10px;
  border: 1px solid #999;
  background: none;
  font: inherit;
  letter-spacing: inherit; }
  input[type=text]:focus, input[type=email]:focus, input[type=number]:focus, input[type=password]:focus, textarea:focus, button:focus {
    border-color: black; }

::-webkit-input-placeholder {
  color: #000; }

:-moz-placeholder {
  color: #000; }

::-moz-placeholder {
  color: #000; }

:-ms-input-placeholder {
  color: #000; }

*:focus::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3); }

*:focus:-moz-placeholder {
  color: rgba(0, 0, 0, 0.3); }

*:focus::-moz-placeholder {
  color: rgba(0, 0, 0, 0.3); }

*:focus:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3); }

body.overlay-on {
  overflow: hidden; }

.pi-modal-close-button {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 36px;
  height: 36px;
  border: 0; }
  .pi-modal-close-button:before, .pi-modal-close-button:after {
    content: "";
    position: absolute;
    top: 0;
    width: 50.91169px;
    height: 2px;
    background-color: red; }
  .pi-modal-close-button:before {
    left: 0;
    transform-origin: top left;
    transform: rotateZ(45deg); }
  .pi-modal-close-button:after {
    right: 0;
    transform-origin: top right;
    transform: rotateZ(-45deg); }

.pi-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  opacity: 0;
  transition: 0.3s; }
  .pi-modal-overlay.on {
    opacity: 1; }

.pi-multi-frame-display {
  position: relative;
  width: 90vw;
  height: 90vh;
  margin: 10vh auto; }
  .pi-multi-frame-display .item-wrapper {
    position: relative;
    width: 100%;
    height: 100%; }
  .pi-multi-frame-display .item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none; }
    .pi-multi-frame-display .item.on {
      display: block; }
  .pi-multi-frame-display .pager {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center; }
    .pi-multi-frame-display .pager .pager-button {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: black;
      border: 0;
      margin: 0 10px;
      transition: 0.3s; }
      .pi-multi-frame-display .pager .pager-button.yah {
        background-color: #00ff99; }
  .pi-multi-frame-display .prev-button, .pi-multi-frame-display .next-button {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    border: 0;
    border-color: #00ff99;
    z-index: 3;
    transform: translateY(-50%) rotateZ(45deg);
    transition: 0.3s; }
    .pi-multi-frame-display .prev-button:hover, .pi-multi-frame-display .next-button:hover {
      border-color: red; }
  .pi-multi-frame-display .prev-button {
    left: 30px;
    border-bottom: 1px solid;
    border-left: 1px solid; }
  .pi-multi-frame-display .next-button {
    right: 30px;
    border-top: 1px solid;
    border-right: 1px solid; }

.pi-dialog .dialog-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.pi-dialog .title {
  font-size: 36px;
  text-align: center;
  padding: 30px 0; }

.pi-dialog .content-box {
  color: #222;
  max-width: 600px;
  overflow: hidden; }
  .pi-dialog .content-box .content {
    padding: 30px;
    border-radius: 4px;
    background-color: white; }
  .pi-dialog .content-box .buttons {
    border-top: 1px solid black;
    text-align: right;
    margin-top: 30px; }
    .pi-dialog .content-box .buttons button {
      color: #00ff99;
      border-color: #00ff99;
      border-radius: 4px;
      transition: 0.3s; }
      .pi-dialog .content-box .buttons button:hover {
        background-color: #00ff99;
        color: black; }

.pi-drawer {
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  transition: left 0.3s;
  background-color: white;
  z-index: 5000;
  display: none; }
  .pi-drawer.on {
    left: 0; }
  .pi-drawer .drawer-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 100px; }

.pi-accordion .container {
  padding: 30px; }

.pi-accordion .item {
  border: 1px solid black;
  overflow: hidden; }

.pi-accordion .title {
  padding: 30px;
  background-color: #eee;
  cursor: pointer;
  transition: 0.3s; }

.pi-accordion .title:hover {
  background-color: #00ff99;
  color: #333; }

.pi-accordion .wrapper {
  position: relative;
  width: 100%;
  transition: 0.3s; }

.pi-accordion .content {
  padding: 60px;
  opacity: 0;
  transition: 0.3s; }

.pi-accordion .item.on > .wrapper > .content {
  opacity: 1; }

.pi-pushmenu {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s; }
  .pi-pushmenu.on {
    opacity: 1; }
  .pi-pushmenu .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); }
  .pi-pushmenu .sled {
    position: absolute;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #ddd;
    overflow: auto;
    transition: width 0.3s; }
  .pi-pushmenu.on .sled {
    width: 320px; }
  .pi-pushmenu .top-bar {
    height: 60px;
    line-height: 60px;
    background-color: #444; }
  .pi-pushmenu .pi-modal-close-button {
    position: absolute;
    top: 15px;
    right: 15px; }
  .pi-pushmenu li {
    position: relative;
    display: block;
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #666; }
    .pi-pushmenu li a {
      display: inline-block;
      width: 100%;
      height: 44px;
      line-height: 44px;
      padding: 0 20px; }
    .pi-pushmenu li:hover {
      background-color: #f2f2f2; }

.pi-crossfader {
  background-color: #ccc;
  height: 60vh; }
  .pi-crossfader .item, .pi-crossfader .image-wrapper, .pi-crossfader .image-wrapper img {
    max-width: 100%;
    max-height: 100%; }
  .pi-crossfader .item {
    display: block;
    opacity: 0;
    transition: opacity 0.3s; }
    .pi-crossfader .item.on {
      opacity: 1; }
  .pi-crossfader .pager {
    bottom: 5%; }

.pi-rotator {
  width: 50vw;
  height: 50vh; }
  .pi-rotator .item-wrapper {
    position: relative;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .pi-rotator .item {
    position: absolute;
    top: auto;
    left: auto;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: none;
    transition: none;
    display: none; }
    .pi-rotator .item.on, .pi-rotator .item.inFromLeft, .pi-rotator .item.inFromRight, .pi-rotator .item.outFromLeft, .pi-rotator .item.outFromRight {
      display: flex;
      opacity: 0;
      transition: opacity 0.6s, transform 0.3s; }
    .pi-rotator .item.on {
      opacity: 1;
      transform: translateX(0%); }
    .pi-rotator .item.inFromLeft {
      transform: translateX(-100%); }
    .pi-rotator .item.inFromRight {
      transform: translateX(100%); }
    .pi-rotator .item.outFromLeft {
      transform: translate(100%); }
    .pi-rotator .item.outFromRight {
      transform: translate(-100%); }
  .pi-rotator .image-wrapper {
    max-width: 100%;
    max-height: 100%; }
    .pi-rotator .image-wrapper img {
      max-width: 100%;
      max-height: 100%; }
  .pi-rotator .prev-button, .pi-rotator .next-button {
    border-color: black; }
  .pi-rotator .prev-button {
    left: -30px; }
  .pi-rotator .next-button {
    right: -30px; }

.pi-modal-overlay .pi-rotator {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%; }
  .pi-modal-overlay .pi-rotator .item-wrapper {
    margin: 10%;
    width: 80%;
    height: 80%; }
  .pi-modal-overlay .pi-rotator .image-wrapper {
    width: auto;
    height: auto; }
  .pi-modal-overlay .pi-rotator .prev-button, .pi-modal-overlay .pi-rotator .next-button {
    border-color: white; }
  .pi-modal-overlay .pi-rotator .prev-button {
    left: 50px; }
  .pi-modal-overlay .pi-rotator .next-button {
    right: 50px; }

.pi-tabber {
  position: relative;
  width: 80%;
  margin: 0 auto;
  border: 1px solid black;
  height: 50px;
  transition: height 0.3s;
  overflow: hidden;
  display: flex; }
  .pi-tabber .tab {
    flex: 1;
    height: 50px;
    border-bottom: 1px solid black;
    background-color: white;
    transition: 0.3s; }
    .pi-tabber .tab:hover {
      background-color: #eee; }
    .pi-tabber .tab.on {
      background-color: #00ff99; }
  .pi-tabber .tab + .tab {
    border-left: 1px solid black; }
  .pi-tabber .tab > button {
    border: 0;
    width: 100%;
    padding: 10px; }
  .pi-tabber .content {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    padding: 30px;
    opacity: 0;
    transition: opacity 0.3s;
    background-color: white; }
  .pi-tabber .content.on {
    opacity: 1; }

html, body {
  font-size: 16px; }

input[type=submit], button, .button {
  color: #ffffff;
  background: #035a9d;
  border: none;
  font-weight: 600;
  cursor: pointer; }

table tr td {
  padding: 10px 10px 10px 0;
  border: solid #ccc;
  border-width: 1px 0 0 0; }

table tr:last-child td {
  border-width: 1px 0; }

.tableBox {
  width: 100%;
  overflow: auto; }

section {
  position: relative;
  padding: 0 20px; }

main {
  width: 100%;
  max-width: 1180px;
  text-align: left;
  margin: 0 auto;
  padding: 20px 0;
  position: relative; }

header {
  position: relative;
  width: 100%;
  height: 40px;
  text-align: center;
  z-index: 1000;
  background: #035a9d; }
  header main {
    max-width: 1280px;
    padding: 0; }
  header .bottom {
    background-color: #035a9d;
    height: 70px; }
  header .top, header .bottom {
    position: relative;
    z-index: 1001; }

#logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 10px;
  color: #fff;
  text-align: left;
  font-family: 'DIN', Helvetica, Arial, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.2em; }
  #logo img {
    height: calc(100% - 10px); }
  #logo span {
    display: none; }

.searchform {
  overflow: hidden;
  position: relative;
  display: inline-block;
  padding: 0;
  width: 100%; }
  .searchform input[type=text] {
    border-width: 0;
    float: left;
    color: #000;
    width: 100%;
    height: 50px;
    background: #fff;
    padding: 2px 50px 0 15px;
    background-color: rgba(255, 255, 255, 0.7);
    transition: all 0.2s ease-out;
    font-size: 15.5px;
    font-weight: 200;
    border-radius: 0 !important; }
    .searchform input[type=text]:focus {
      background: #fff; }
  .searchform button {
    display: block;
    float: left;
    border-width: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-left: -50px;
    background: url(../images/searchIcon.svg) center center no-repeat;
    background-size: 60%;
    opacity: 0.5; }
    .searchform button.focus {
      opacity: 1; }

#searchPageForm input[type=text] {
  background: #eee;
  border: 1px solid #aaa; }
  #searchPageForm input[type=text]:focus {
    background: #fff;
    border-color: #888; }

#searchformNav {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center; }
  #searchformNav input[type=text] {
    max-width: 500px; }

#searchIntro {
  position: relative;
  z-index: 3;
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 1.4em;
  width: 100%;
  max-width: 480px;
  padding: 20px;
  margin: 0 auto; }
  #searchIntro h1 {
    font-weight: 900;
    margin-bottom: 0;
    letter-spacing: -0.05em;
    line-height: 1.1em;
    font-size: 45px; }
    #searchIntro h1 b {
      font-size: 67px;
      display: block;
      font-weight: 900; }
  #searchIntro p {
    font-size: 17px;
    line-height: 1.5em;
    display: none;
    text-shadow: 0 0 10px black; }
  #searchIntro .searchform {
    height: 100%;
    padding: 15px 0 0 0;
    margin: 20px 0 15px 0; }
    #searchIntro .searchform * {
      vertical-align: middle; }

paging {
  display: block;
  text-align: center;
  padding: 5px 0; }
  paging > * {
    display: inline-block;
    padding: 0 5px; }
    paging > *.current {
      background: #eee; }

#mobileIntro main {
  text-align: center; }

.autocomplete-suggestions {
  background: #FFF;
  cursor: default;
  overflow: visible;
  box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.2);
  margin-top: 2px; }

.autocomplete-suggestion {
  padding: 2px 15px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  color: #888;
  transition: all 0.15s ease-out;
  width: calc(100% + 2px);
  background: #fff; }
  .autocomplete-suggestion strong {
    color: #035a9d; }

.autocomplete-no-suggestion {
  padding: 2px 5px; }

.autocomplete-selected {
  background: #eee;
  color: #444; }

.autocomplete-group {
  padding: 2px 5px; }

.autocomplete-group strong {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  display: block;
  border-bottom: 1px solid #000; }

.menuButton {
  padding: 0;
  color: #fff;
  display: block;
  height: 100%;
  width: 50px;
  float: left;
  text-transform: uppercase;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer; }

.menuButton span {
  display: block;
  line-height: 20px;
  height: 20px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  bottom: -20px;
  left: 0;
  text-align: center; }

.hamburger {
  display: block;
  height: 4px;
  width: 30px;
  background-color: #fff;
  vertical-align: middle;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.hamburger:before, .hamburger:after {
  content: '';
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: #fff;
  right: 0;
  top: -10px; }

.hamburger:after {
  top: auto;
  bottom: -10px; }

body.openNav .menuButton {
  opacity: 1 !important;
  color: #fff !important; }
  body.openNav .menuButton .hamburger {
    background-color: transparent !important; }

body.openNav:hover .hamburger:before, body.openNav .hamburger:before {
  width: 100%;
  top: 0;
  right: 0;
  transform: rotate(45deg);
  background-color: #fff !important; }

body.openNav:hover .hamburger:after, body.openNav .hamburger:after {
  width: 100%;
  bottom: 0;
  right: 0;
  transform: rotate(-45deg);
  background-color: #fff !important; }

.bigMenu {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: calc(100vh - 40px);
  z-index: 1002;
  overflow: auto;
  text-align: left;
  background: #035a9d;
  color: #fff;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(5%);
  transition: all 0.2s ease-out, visibility 0s 0.2s; }
  .bigMenu ul, .bigMenu li {
    padding: 0; }
  .bigMenu h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 1.5em;
    text-transform: uppercase; }
  .bigMenu .content {
    float: none;
    width: auto;
    margin-bottom: 10px;
    line-height: 1.5em; }
    .bigMenu .content .menu {
      column-count: 1; }
  .bigMenu .menuBoxSidebar {
    width: auto;
    float: none;
    padding: 20px 0 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5); }
  .bigMenu .menu {
    width: auto;
    margin: 0 -10px; }
    .bigMenu .menu ul, .bigMenu .menu li, .bigMenu .menu a {
      display: block;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid; }
    .bigMenu .menu > li {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      padding: 0 10px; }
      .bigMenu .menu > li.current_page_ancestor, .bigMenu .menu > li.current_page_item {
        background: rgba(0, 0, 0, 0.1); }
        .bigMenu .menu > li.current_page_ancestor .current_page_item a, .bigMenu .menu > li.current_page_item .current_page_item a {
          color: #fff; }
      .bigMenu .menu > li > a {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 5px;
        padding: 4px 10px 4px 0;
        color: #fff; }
      .bigMenu .menu > li .children li {
        margin: 0 0 8px 0;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        display: inline-block;
        width: 100%; }
      .bigMenu .menu > li .children a {
        color: rgba(255, 255, 255, 0.6);
        line-height: 1.2em; }
        .bigMenu .menu > li .children a:hover {
          color: #fff; }
      .bigMenu .menu > li .children .children {
        padding: 5px 0 0 10px;
        margin: 3px;
        border-left: 1px solid rgba(255, 255, 255, 0.5); }
  .bigMenu .menu > li > .children > li:last-child {
    padding-bottom: 10px;
    display: inline-block; }

body.openNav {
  overflow: hidden; }
  body.openNav #menuBox {
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-out, visibility 0s;
    transform: translateY(0); }

body.home #hero {
  display: none;
  line-height: 0;
  padding: 0;
  margin-bottom: 0; }

body.inside-page .hero-image {
  width: 100%; }

#hero {
  position: relative;
  margin-bottom: 20px; }
  #hero .hot-spot {
    position: absolute;
    z-index: 0; }
    #hero .hot-spot .flyout {
      display: none; }
    #hero .hot-spot .blip {
      position: absolute;
      width: 7vw;
      height: 7vw;
      line-height: 6.5vw;
      font-size: 5vw;
      background-color: rgba(38, 47, 53, 0.6);
      box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
      border-width: 0;
      border-radius: 50%;
      cursor: pointer;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      transition: background-color 0.3s;
      text-align: center;
      color: #fff; }

body.home #hero .blip {
  color: transparent !important; }

#hotspot-mobile-list {
  margin: 0;
  padding: 0; }
  #hotspot-mobile-list li {
    padding-left: 45px;
    margin-bottom: 20px;
    list-style: none;
    counter-increment: step-counter; }
    #hotspot-mobile-list li:before {
      content: counter(step-counter);
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      background-color: rgba(216, 242, 251, 0.9);
      border: 2px solid #035a9d;
      border-radius: 50%;
      cursor: pointer;
      text-align: center;
      color: #035a9d;
      position: absolute;
      top: -4px;
      left: -5px; }
    #hotspot-mobile-list li h3 {
      margin-bottom: 5px; }

body.home #hero {
  height: auto;
  min-height: 280px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column; }
  body.home #hero #homeHeroBox {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 210px);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  body.home #hero #homeHero {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    body.home #hero #homeHero .hero-image {
      display: none; }
  body.home #hero div.hot-spot {
    display: none; }

#homeHeroCTAs {
  position: relative;
  width: 100%;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 1.4em;
  overflow: hidden; }
  #homeHeroCTAs > * {
    padding: 15px 20px;
    margin-bottom: 1px; }
  #homeHeroCTAs h3 {
    font-weight: 800;
    color: #ffffff;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0; }
  #homeHeroCTAs .caption {
    background: #262f35;
    text-align: center;
    display: none; }
  #homeHeroCTAs a {
    display: block;
    background: #262f35;
    padding-right: 40px;
    position: relative; }
    #homeHeroCTAs a:hover {
      background: #1e2224; }
    #homeHeroCTAs a:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      width: 15px;
      height: 15px;
      border: solid rgba(255, 255, 255, 0.3);
      border-width: 4px 4px 0 0;
      transform: translateY(-50%) rotate(45deg); }

#breadcrumb {
  margin-bottom: 10px;
  position: relative; }
  #breadcrumb > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px; }
  #breadcrumb a {
    margin-right: 20px;
    position: relative;
    display: inline-block; }
    #breadcrumb a:after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border: solid #888;
      border-width: 1px 1px 0 0;
      position: absolute;
      right: -10px;
      top: 45%;
      transform: rotate(45deg) translateY(-50%); }

#subMenuButton {
  display: block !important;
  position: absolute !important;
  margin: 0 !important;
  right: -10px;
  top: 50%;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  cursor: pointer; }
  #subMenuButton:after {
    display: none !important; }
  #subMenuButton:before {
    content: '';
    display: block;
    width: 35%;
    height: 35%;
    border: solid #035a9d;
    border-width: 0 0 4px 4px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }

#mobileSubMenu {
  background-color: #035a9d;
  margin: 0 -20px 20px -20px;
  padding: 10px 0;
  display: none; }
  #mobileSubMenu.show {
    display: block; }
  #mobileSubMenu h3 a {
    color: #fff;
    padding: 0 20px;
    display: inline-block; }
  #mobileSubMenu ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    #mobileSubMenu ul li {
      padding: 0;
      margin: 0 !important;
      list-style: none; }
      #mobileSubMenu ul li.current_page_item a {
        background-color: rgba(0, 0, 0, 0.1);
        color: #fff; }
      #mobileSubMenu ul li a {
        display: block;
        width: 100%;
        padding: 5px 20px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.6); }
        #mobileSubMenu ul li a:hover {
          text-decoration: none;
          color: #fff; }

#pageAnchors {
  display: block;
  margin: 0 0 20px 0;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #ccc; }
  #pageAnchors li {
    display: inline-block;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0; }
    #pageAnchors li:after {
      content: '|';
      margin: 0 5px; }
    #pageAnchors li:last-child:after {
      display: none; }

.mainContent {
  position: relative;
  word-wrap: break-word; }
  .mainContent main .pageTitle {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px; }
    .mainContent main .pageTitle.hasMenu {
      padding-right: 20px; }
  .mainContent main .manualMeta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: -10px;
    font-size: 14px;
    line-height: 40px; }
    .mainContent main .manualMeta .lastUpdated {
      font-style: italic;
      color: #888; }
    .mainContent main .manualMeta .printPageLink {
      text-transform: uppercase;
      padding-left: 20px;
      background: url(../images/print-icon.png) left center no-repeat; }
  .mainContent ul {
    margin: 0 0 20px 0; }
    .mainContent ul > li {
      margin: 0 0 10px 0;
      position: relative;
      line-height: 1.5em; }
  .mainContent ol > li {
    margin: 0 0 10px 0;
    position: relative;
    line-height: 1.5em; }

.simpleTagList {
  font-style: italic;
  font-size: 14px;
  line-height: 1.5em;
  padding: 5px;
  margin: 5px 0;
  background: #eee; }

.tagList .tags {
  line-height: 0; }

.tagList a {
  display: inline-block;
  padding: 0 7px;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
  background: #e5e5e5;
  margin: 4px 4px 0 0;
  font-weight: normal;
  transition: all 0.15s ease-out; }
  .tagList a:hover {
    text-decoration: none;
    background: #035a9d;
    color: #fff; }

.sidebar ul li {
  padding: 0;
  margin: 0;
  list-style: none; }
  .sidebar ul li:before {
    display: none; }
  .sidebar ul li.current_page_item a {
    color: #000; }

.sidebar.childPageList {
  display: none; }

.sidebar.tagList {
  margin-top: 20px; }

footer {
  padding: 0 30px;
  background: #eee; }
  footer a {
    font-weight: 600;
    color: #035a9d; }
    footer a:hover {
      text-decoration: underline; }
  footer main {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px 0;
    font-size: 14px; }
    footer main .links ul {
      display: block;
      overflow: hidden;
      margin: 5px 0;
      padding: 0;
      text-align: center;
      line-height: 1.5em; }
      footer main .links ul li {
        display: inline-block;
        padding: 0 5px; }
        footer main .links ul li:last-child {
          margin-right: 0;
          border-right: none; }

#legal {
  padding: 0 30px;
  background: #555;
  color: #fff;
  font-size: 12px; }
  #legal main {
    padding: 5px 0; }
  #legal .links {
    float: right; }
    #legal .links ul, #legal .links li, #legal .links a {
      list-style: none;
      display: inline-block;
      line-height: 12px; }
    #legal .links li {
      padding: 0 10px;
      border-left: 1px solid #fff; }
      #legal .links li:first-child {
        border-width: 0; }
    #legal .links a:hover {
      text-decoration: underline; }

/* */
.photo-gallery {
  padding: 20px 0 10px 0;
  border: solid #ccc;
  border-width: 0;
  margin-bottom: 20px; }
  .photo-gallery .gallery-thumb {
    width: auto;
    margin: 0 0 20px 0;
    position: relative;
    overflow: hidden;
    display: block; }
    .photo-gallery .gallery-thumb .image {
      width: 100%;
      height: 50vw;
      position: relative;
      background-size: 90%;
      background-position: center center;
      background-repeat: no-repeat;
      border: 1px solid #ccc;
      overflow: hidden; }
      .photo-gallery .gallery-thumb .image img {
        max-height: calc(100% - 20px);
        max-width: calc(100% - 20px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        .photo-gallery .gallery-thumb .image img:last-child {
          display: none; }
      .photo-gallery .gallery-thumb .image:after, .photo-gallery .gallery-thumb .image:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5) url(/wp-content/themes/uc/images/zoomIcon.svg) center center no-repeat;
        background-size: 40px;
        transition: all 0.2s ease-out;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2; }
      .photo-gallery .gallery-thumb .image:before {
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        background-size: 60%;
        top: auto;
        bottom: 0;
        opacity: 1; }
    .photo-gallery .gallery-thumb .caption {
      padding: 10px 5px 0 5px;
      text-align: center;
      line-height: 1.2em;
      font-weight: normal;
      font-style: italic; }
    .photo-gallery .gallery-thumb:hover {
      text-decoration: none; }
      .photo-gallery .gallery-thumb:hover .image:after {
        opacity: 1; }
      .photo-gallery .gallery-thumb:hover .image:before {
        opacity: 0; }

#fancybox-overlay {
  background-color: #000 !important; }

#fancybox-close {
  position: fixed;
  top: 0;
  right: 0;
  background-image: none;
  cursor: pointer;
  height: 60px;
  width: 60px; }
  #fancybox-close:hover {
    opacity: 1; }
  #fancybox-close:before, #fancybox-close:after {
    content: '';
    height: 60%;
    width: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%) rotate(45deg); }
  #fancybox-close:after {
    transform: translate(-50%, -50%) rotate(-45deg); }

#fancybox-left, #fancybox-right {
  position: fixed;
  width: 50%;
  visibility: visible;
  display: block; }
  #fancybox-left:hover, #fancybox-right:hover {
    opacity: 1; }
  #fancybox-left span, #fancybox-right span {
    display: none; }
  #fancybox-left:after, #fancybox-right:after {
    content: '';
    width: 30px;
    height: 30px;
    display: block;
    border: solid #fff;
    border-width: 4px 4px 0 0;
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    top: 50%;
    right: 40px; }

#fancybox-left:after {
  transform: translateY(-50%) rotate(-135deg);
  right: auto;
  left: 40px; }

.fancybox-bg {
  display: none; }

#backToTop {
  display: block;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0;
  cursor: pointer;
  transition: all 0.2s ease-out, visibility 0s 0.2s;
  opacity: 0;
  visibility: hidden; }
  #backToTop:hover {
    background: #000; }
  #backToTop:before {
    content: '';
    display: block;
    width: 35%;
    height: 35%;
    border: solid #ffffff;
    border-width: 4px 4px 0 0;
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }
  #backToTop.show {
    transition: all 0.2s ease-out, visibility 0s 0s;
    opacity: 1;
    visibility: visible; }

.archiveList article {
  padding: 10px 0;
  border-bottom: 1px solid #cccccc; }
  .archiveList article:first-child {
    border-top: 1px solid #cccccc; }
  .archiveList article h2 {
    margin-bottom: 0; }

#sitemap {
  margin: 0;
  padding: 0;
  display: block;
  position: relative; }
  #sitemap li, #sitemap ul, #sitemap div, #sitemap a {
    margin: 0;
    padding: 0;
    display: block;
    position: relative; }
  #sitemap li:before {
    display: none; }
  #sitemap li > input[type="checkbox"] {
    position: absolute;
    top: 7px;
    left: 5px; }
  #sitemap a {
    display: inline; }
    #sitemap a:before {
      content: '—';
      color: #888;
      position: absolute;
      top: 7px;
      left: -20px;
      line-height: 0;
      font-weight: 200; }
  #sitemap div {
    padding-top: 5px;
    padding-bottom: 5px; }
    #sitemap div.selected {
      background: #eff8ff; }
  #sitemap > li > div {
    background: #f5f5f5; }
    #sitemap > li > div > a:before {
      display: none; }
  #sitemap li div {
    padding-left: 30px; }
  #sitemap li li div {
    padding-left: 50px; }
  #sitemap li li li div {
    padding-left: 70px; }
  #sitemap li li li li div {
    padding-left: 90px; }

#pdfActionBar {
  background: #e5e5e5;
  border-top: 1px solid #ccc; }
  #pdfActionBar.stuck {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;
    transition: box-shadow 0.2s; }
  #pdfActionBar main {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    #pdfActionBar main:before, #pdfActionBar main:after {
      content: '';
      display: block;
      position: absolute;
      left: 5px;
      width: 0;
      height: 0;
      bottom: 100%;
      border-style: solid;
      border-width: 0 11px 11px 11px;
      border-color: transparent transparent #aaa transparent; }
    #pdfActionBar main:after {
      left: 6px;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #e5e5e5 transparent; }
    #pdfActionBar main > div {
      padding: 0 0 0 5px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      position: relative; }
  #pdfActionBar .step2 {
    display: none; }
  #pdfActionBar .step2 span {
    font-style: italic;
    line-height: 1.4em; }
  #pdfActionBar span {
    display: inline-block; }
  #pdfActionBar button.cancel {
    background: #666; }

#pdfLinkBox {
  display: none;
  padding: 10px;
  text-align: center;
  background: yellow; }

#printBox {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 70px);
  background: #fff;
  display: none; }
  #printBox main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0; }
  #printBox iframe {
    border: 1px solid #666;
    width: 100%;
    height: calc(100% - 60px); }

body.printBoxOpen {
  overflow: hidden; }

body.printBoxOpen #printBox {
  display: block; }

body.printBoxOpen #pdfActionBar .step2 {
  display: flex; }

body.printBoxOpen #pdfActionBar .step1 {
  display: none; }

@media screen and (min-width: 400px) {
  #searchIntro h1 {
    font-size: 57px; }
    #searchIntro h1 b {
      font-size: 85px; } }

@media screen and (min-width: 550px) {
  .bigMenu .content .menu {
    column-count: 2; } }

@media screen and (min-width: 600px) {
  #homeHeroCTAs {
    display: flex;
    justify-content: space-between;
    align-items: stretch; }
    #homeHeroCTAs a {
      width: 50%; } }

@media screen and (max-width: 767px) and (orientation: landscape) {
  body.home #hero #homeHeroBox {
    height: calc(100vh - 40px); } }

@media screen and (max-width: 1280px) {
  header .top {
    padding: 0 20px; } }

@media screen and (min-width: 768px) {
  body {
    padding-top: 60px; }
  h1 {
    font-size: 2.5em; }
  h2 {
    font-size: 2em; }
  h3 {
    font-size: 1.5em; }
  h4 {
    font-size: 1.2em; }
  h5 {
    font-size: 1.2em; }
  section {
    padding: 0 30px; }
  main {
    padding: 30px 0; }
  header {
    position: fixed;
    top: 0;
    left: 0;
    height: 60px; }
  #logo img {
    height: calc(100% - 15px);
    margin-right: 10px; }
  #logo span {
    display: block; }
  .menuButton {
    width: 60px; }
  .bigMenu .content .menu {
    column-count: 3; }
  .bigMenu {
    top: 60px;
    height: calc(100vh - 60px);
    padding: 20px;
    position: fixed; }
  #navBox > li > .children {
    column-count: 2; }
  #mobileSubMenu {
    margin: 0 -30px 20px -30px; }
    #mobileSubMenu h3 a {
      padding: 0 30px; }
    #mobileSubMenu ul li a {
      padding: 5px 30px; }
  #hero {
    display: block;
    margin-bottom: 20px; }
    #hero .hot-spot {
      position: absolute;
      z-index: 0;
      display: block; }
      #hero .hot-spot:hover .blip, #hero .hot-spot.on .blip {
        background-color: #035a9d !important;
        color: rgba(216, 242, 251, 0.9); }
      #hero .hot-spot:hover .blip:before, #hero .hot-spot:hover .blip:after, #hero .hot-spot.on .blip:after {
        background-color: rgba(216, 242, 251, 0.9); }
      #hero .hot-spot.on {
        z-index: 1001; }
      #hero .hot-spot .blip {
        width: 40px;
        height: 40px;
        font-size: 18px;
        line-height: 40px; }
        #hero .hot-spot .blip:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #fff;
          transition: 0.3s;
          animation: blipFlash 1s infinite; }
      #hero .hot-spot .flyout {
        display: block;
        position: absolute;
        bottom: calc(50% + 10px);
        left: 50%;
        width: 0;
        background-color: white;
        border: 2px solid #035a9d;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.3s, width 0.3s, margin 0.3s;
        transform: translateX(-50%);
        line-height: 1.3em;
        color: #000;
        margin-bottom: 0;
        font-weight: 400; }
        #hero .hot-spot .flyout h5 {
          margin-bottom: 10px; }
        #hero .hot-spot .flyout .wrapper {
          position: absolute;
          bottom: 0;
          left: 0;
          min-height: 100%;
          width: 300px;
          padding: 15px; }
          #hero .hot-spot .flyout .wrapper:hover .see-more {
            background-color: rgba(216, 242, 251, 0.9); }
        #hero .hot-spot .flyout .see-more {
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 100%;
          background: none;
          border: 0;
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          transition: background-color 0.15s ease-out;
          display: none; }
          #hero .hot-spot .flyout .see-more:before {
            content: "";
            position: absolute;
            top: calc(50% + 2px);
            left: calc(50% - 5px);
            transform: translate(-50%, -50%) rotate(45deg);
            width: 12px;
            height: 12px;
            border-top: 2px solid #035a9d;
            border-right: 2px solid #035a9d;
            transition: border-color 0.15s ease-out; }
      #hero .hot-spot.on .flyout {
        opacity: 1; }
      #hero .hot-spot.right .flyout {
        left: auto; }
      #hero .hot-spot.right .wrapper {
        left: auto; }
      #hero .hot-spot.right.on .blip:after {
        transform: translate(-50%, -50%) rotate(-180deg); }
  body.openFlyout .blip:after {
    display: none; }
  body.openFlyout #hero .hot-spot .blip {
    background: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.7); }
  @keyframes blipFlash {
    from {
      opacity: 0.3; }
    to {
      opacity: 0; } }
  #hero {
    position: relative;
    display: block;
    width: 100%;
    background-size: cover;
    background-position: center center; }
  body.inside-page #hero {
    margin-top: 0; }
  body.home #hero {
    height: calc(100vh - 60px);
    margin-bottom: 0; }
    body.home #hero #homeHeroBox {
      position: absolute;
      height: 100%; }
    body.home #hero #homeHero {
      height: 100%; }
      body.home #hero #homeHero .hot-spot {
        display: none; }
        body.home #hero #homeHero .hot-spot .flyout {
          border-radius: 0px !important;
          border: none !important;
          color: #fff !important;
          background: #262f35;
          box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3); }
          body.home #hero #homeHero .hot-spot .flyout:hover {
            background: #035a9d !important; }
          body.home #hero #homeHero .hot-spot .flyout h5 {
            color: #ffffff;
            font-size: 18px;
            font-weight: 900;
            margin-bottom: 5px; }
  #hotspot-mobile-list {
    display: none; }
  #homeHeroCTAs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    overflow: visible;
    position: absolute;
    bottom: 0;
    left: 0; }
    #homeHeroCTAs > * {
      margin-bottom: 0; }
    #homeHeroCTAs h3 {
      font-weight: 800;
      color: #ffffff;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0; }
    #homeHeroCTAs .caption {
      padding: 15px 15vw;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-100%);
      display: block;
      background: rgba(38, 47, 53, 0.5); }
    #homeHeroCTAs a {
      display: block;
      width: 50%;
      float: none; }
  #searchIntro {
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translateX(-50%); }
    #searchIntro h1 {
      font-size: 57px; }
      #searchIntro h1 b {
        font-size: 85px;
        display: block;
        font-weight: 900; }
    #searchIntro p {
      display: block; }
  .searchform input[type=text] {
    font-size: 20px; }
  #mobileIntro {
    display: none; }
  #breadcrumb b, #breadcrumb strong {
    display: inline-block; }
  footer main {
    flex-direction: row;
    justify-content: space-between; }
  /* */
  .photo-gallery .gallery-thumb {
    float: left;
    width: calc(50% - 10px);
    margin: 0 20px 20px 0; }
    .photo-gallery .gallery-thumb:nth-child(2n) {
      margin-right: 0; }
    .photo-gallery .gallery-thumb:nth-child(2n+1) {
      clear: both; }
    .photo-gallery .gallery-thumb .image {
      width: 100%;
      height: 240px; }
  #printBox {
    top: 50%;
    height: calc(100vh - 110px);
    transform: translateY(-50%); } }

@media screen and (min-width: 900px) {
  .alignleft, .alignright, .aligncenter, .alignnone {
    margin: 5px 0 20px 0;
    width: auto;
    height: auto;
    max-width: 50%;
    display: block; }
    .alignleft img, .alignright img, .aligncenter img, .alignnone img {
      width: 100%;
      height: auto; }
  .alignleft {
    float: left;
    margin-right: 20px; }
  .alignright {
    float: right;
    margin-left: 20px; }
  .aligncenter {
    margin: 5px auto 20px auto;
    text-align: center; }
    .aligncenter img {
      margin: 0 auto; }
  .bigMenu .content .menu {
    column-count: 4; }
  body.home #hero {
    height: calc(80vw - 60px); } }

@media screen and (min-width: 1024px) {
  #hero {
    margin-top: 0 !important; }
  body.home #hero {
    height: calc(100vh - 60px); }
  #navBox > li > a {
    padding: 20px; }
  #navBox > li > .children {
    column-count: 3; }
  #main {
    text-align: left; }
  .the_content_wrapper {
    position: relative;
    float: left;
    width: calc(75% - 30px); }
  .sidebar {
    position: relative;
    float: right;
    width: 25%;
    margin-bottom: 20px; }
    .sidebar.tagList {
      margin-top: 0; }
  a.arrow {
    font-weight: 600;
    color: #035a9d;
    line-height: 36px; }
    a.arrow:hover {
      text-decoration: none; }
    a.arrow:after {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-top: 2px solid #035a9d;
      border-right: 2px solid #035a9d;
      transform: rotate(45deg);
      margin-left: 10px;
      vertical-align: middle; } }

@media screen and (max-width: 1024px) {
  body {
    padding-bottom: 0 !important; }
  #backToTop {
    bottom: 0 !important;
    right: 0;
    width: 40px;
    height: 40px; }
  .printPageLink {
    display: none; }
  #sitemap li > input[type="checkbox"] {
    display: none; }
  #sitemap div.selected {
    background: inherit !important; }
  #pdfActionBar {
    display: none; } }

@media (min-width: 1100px) {
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px; }
  h2 {
    font-size: 2.2em; }
  h3 {
    font-size: 1.5em; }
  h4 {
    font-size: 1.2em;
    margin-bottom: 5px; }
  h5 {
    font-size: 1em; }
  .bigMenu {
    height: auto;
    overflow: hidden;
    padding: 20px;
    width: calc(100% - 20px);
    top: 70px;
    left: 10px; }
    .bigMenu .content {
      float: left;
      width: calc(75% - 20px);
      margin-bottom: 0; }
      .bigMenu .content .menu {
        column-count: 4; }
        .bigMenu .content .menu .children .children {
          display: none; }
    .bigMenu .menuBoxSidebar {
      width: 25%;
      float: right;
      padding: 0 0 0 20px;
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      border-top: none; }
  body.openNav {
    overflow: auto; }
  #searchIntro {
    position: fixed;
    left: 50%;
    top: 10vw;
    color: #fff;
    text-align: center;
    font-size: 18px;
    line-height: 1.4em;
    max-width: 540px; }
    #searchIntro h1 {
      font-size: 57px; }
      #searchIntro h1 b {
        font-size: 85px;
        display: block;
        font-weight: 900; }
  body.home #hero {
    height: calc(100vh - 60px); }
    body.home #hero #homeHero {
      width: auto;
      height: auto; }
      body.home #hero #homeHero .hero-image {
        display: block; }
      body.home #hero #homeHero .hot-spot {
        display: block; }
  #homeHeroCTAs {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    background: transparent; }
    #homeHeroCTAs > * {
      padding: 15px 20px; }
    #homeHeroCTAs h3 {
      font-weight: 800;
      color: #ffffff;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0; }
    #homeHeroCTAs .caption {
      width: 50%;
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      text-align: left;
      padding: 15px 20px; }
    #homeHeroCTAs a {
      width: calc(25% - 1px); }
  .mainContent main .manualMeta {
    margin-top: -20px; }
  .the_content_wrapper {
    min-height: 700px; }
  #mobileSubMenu, #subMenuButton {
    display: none !important; }
  .sidebar.childPageList {
    display: block; }
    .sidebar.childPageList ul {
      margin-bottom: 0;
      padding: 0; }
      .sidebar.childPageList ul li {
        border-bottom: 1px solid #dddddd; }
      .sidebar.childPageList ul:last-child {
        border-bottom: 0; }
    .sidebar.childPageList a {
      display: block;
      width: 100%;
      padding: 5px 10px;
      line-height: 24px; }
      .sidebar.childPageList a:hover {
        text-decoration: none;
        background-color: #eee; } }

@media (min-width: 1280px) {
  .bigMenu .content {
    margin-bottom: 0; }
    .bigMenu .content .menu {
      column-count: 5; }
  #hero {
    display: block; }
    #hero:after {
      display: none; }
  .home .mainContent:before, .home .mainContent:after {
    display: none; }
  .home .mainContent main {
    margin-top: auto; }
  .has-banner #hero {
    display: block; }
    .has-banner #hero:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7); }
  .has-banner .mainContent:before {
    content: "";
    position: absolute;
    width: 100%;
    max-width: 1280px;
    height: 400px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0; }
  .has-banner .mainContent:after {
    content: "";
    position: absolute;
    width: calc(100% + 30px);
    max-width: 1410px;
    height: 400px;
    background: linear-gradient(to top, white 0%, white 50%, rgba(255, 255, 255, 0) 100%);
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0; }
  .has-banner .mainContent main {
    position: relative;
    margin-top: -160px; }
    .has-banner .mainContent main h1 {
      color: #035a9d; }
  .has-banner .mainContent #main {
    position: relative;
    background-color: white;
    padding-top: 30px;
    z-index: 1; }
  .has-banner .mainContent .popular-topics {
    margin-top: -77px; }
  #homeHeroCTAs {
    font-size: 16px;
    line-height: 22px; }
    #homeHeroCTAs h3 {
      font-size: 18px; }
  #audiences figure .image {
    height: 190px !important; } }

@media (min-width: 1400px) {
  #homeHeroCTAs {
    font-size: 18px; }
    #homeHeroCTAs h3 {
      font-size: 20px; } }

@media print {
  @page {
    size: 8.5in 11in; }
  @page :left {
    @bottom-right {
      margin: 10pt 0 30pt 0;
      border-top: .25pt solid #666;
      content: "Our Cats";
      font-size: 9pt;
      color: #333; }
    @bottom-left {
      margin: 10pt 0 30pt 0;
      border-top: .25pt solid #666;
      content: counter(page);
      font-size: 9pt; } }
  body {
    font-size: 10pt; }
  #bigMenu, header, .sidebar, .printPageLink, footer .links, #backToTop {
    display: none; }
  footer main {
    padding: 10px;
    text-align: center;
    justify-content: center; }
  .photo-gallery .gallery-thumb {
    page-break-before: always;
    page-break-after: always;
    height: 90vh;
    width: 100%; }
    .photo-gallery .gallery-thumb .image {
      border-width: 0;
      box-shadow: none;
      width: 100%;
      height: 80% !important; }
      .photo-gallery .gallery-thumb .image img:last-child {
        display: block; }
      .photo-gallery .gallery-thumb .image img:first-child {
        display: none; } }
