.pi-tabber {
	$pi-tabber-border-style: 1px solid black;

	position: relative;
	width: 80%;
	margin: 0 auto;
	border: $pi-tabber-border-style;
	height: 50px;
	transition: height 0.3s;
	overflow: hidden;
	display: flex;

	.tab {
		flex: 1;
		height: 50px;
		border-bottom: $pi-tabber-border-style;
		background-color: white;
		transition: 0.3s;

		&:hover {
			background-color: #eee; }

		&.on {
			background-color: $awesome-green; } }

	.tab + .tab {
		border-left: $pi-tabber-border-style; }

	.tab > button {
		border: 0;
		width: 100%;
		padding: 10px; }

	.content {
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		padding: 30px;
		opacity: 0;
		transition: opacity 0.3s;
		background-color: white; }

	.content.on {
		opacity: 1; } }
