input[type=text], input[type=email], input[type=number], input[type=password], textarea, button {
	padding: 5px 10px;
	border: 1px solid #999;
	background: none;
	font: inherit;
	letter-spacing: inherit;

	&:focus {
		border-color: black; } }

::-webkit-input-placeholder {
	color: #000; }
:-moz-placeholder {
	color: #000; }
::-moz-placeholder {
	color: #000; }
:-ms-input-placeholder {
	color: #000; }


*:focus::-webkit-input-placeholder {
	color: rgba(0,0,0,0.3); }
*:focus:-moz-placeholder {
	color: rgba(0,0,0,0.3); }
*:focus::-moz-placeholder {
	color: rgba(0,0,0,0.3); }
*:focus:-ms-input-placeholder {
	color: rgba(0,0,0,0.3); }

