

@media (min-width: 1100px) {




	h1 {
		font-size: 2.5em;
		margin-bottom: 20px; }

	h2 {
		font-size: 2.2em; }

	h3 {
		font-size: 1.5em; }

	h4 {
		font-size: 1.2em;
		margin-bottom: 5px; }

	h5 {
		font-size: 1em; }

	.bigMenu {
		height: auto;
		overflow: hidden;
		padding: 20px;
		width: calc(100% - 20px);
		top: 70px;
		left: 10px;

		.content {
			float: left;
			width: calc(75% - 20px);
			margin-bottom: 0;

			.menu {
				column-count: 4;

				.children .children {
					display: none; } } }

		.menuBoxSidebar {
			width: 25%;
			float: right;
			padding: 0 0 0 20px;
			border-left: 1px solid rgba(255,255,255,0.5);
			border-top: none; } }

	body.openNav {
		overflow: auto; }

	#searchIntro {
		position: fixed;
		left: 50%;
		top: 10vw;
		color: #fff;
		text-align: center;
		font-size: 18px;
		line-height: 1.4em;
		max-width: 540px;

		h1 {
			font-size: 57px;

			b {
				font-size: 85px;
				display: block;
				font-weight: 900; } } }

	body.home #hero {
		height: calc(100vh - 60px);

		#homeHero {
			width: auto;
			height: auto;

			.hero-image {
				display: block; }

			.hot-spot {
				display: block; } } }

	#homeHeroCTAs {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		color: rgba(255,255,255,0.7);
		font-size: 14px;
		background: transparent;

		& > * {
			padding: 15px 20px; }

		h3 {
			font-weight: 800;
			color: #ffffff;
			margin-bottom: 0;
			font-size: 16px;
			line-height: 1.5em;
			letter-spacing: 0; }

		.caption {
			width: 50%;
			position: relative;
			top: auto;
			left: auto;
			transform: none;
			text-align: left;
			padding: 15px 20px; }

		a {
			width: calc(25% - 1px); } }

	.mainContent {
		main {
			.manualMeta {
				margin-top: -20px; } } }

	.the_content_wrapper {
		min-height: 700px; }

	#mobileSubMenu, #subMenuButton {
		display: none !important; }

	.sidebar.childPageList {
		display: block;

		ul {
			margin-bottom: 0;
			padding: 0;

			li {
				border-bottom: 1px solid $light-grey; }

			&:last-child {
				border-bottom: 0; } }

		a {
			display: block;
			width: 100%;
			padding: 5px 10px;
			line-height: 24px;

			&:hover {
				text-decoration: none;
				background-color: #eee; } } } }




@media (min-width: 1280px) {

	.bigMenu {

		.content {
			margin-bottom: 0;

			.menu {
				column-count: 5; } } }

	#hero {
		display: block;

		&:after {
			display: none; } }

	.home .mainContent {
		&:before, &:after {
			display: none; }

		main {
			margin-top: auto; } }

	.mainContent {
		main {
 } }			//margin-top: 50px

	.has-banner {
		#hero {
			display: block;
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(255, 255, 255, 0.7); } }

		.mainContent {
			$neg-margin: -160px;

			&:before {
				content: "";
				position: absolute;
				width: 100%;
				max-width: 1280px;
				height: 400px;
				background-color: white;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
				top: 100px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 0; }

			&:after {
				content: "";
				position: absolute;
				width: calc(100% + 30px);
				max-width: 1410px;
				height: 400px;
				background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
				top: 200px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 0; }

			main {
				position: relative;
				margin-top: $neg-margin;

				h1 {
					color: $deep-blue; } }

			#main {
				position: relative;
				background-color: white;
				padding-top: 30px;
				z-index: 1; }

			.popular-topics {
				margin-top: -77px; } } }

	#homeHeroCTAs {
		font-size: 16px;
		line-height: 22px;

		h3 {
			font-size: 18px; } }

	#audiences {
		figure {
			.image {
				height: 190px !important; } } } }


@media (min-width: 1400px) {

	#homeHeroCTAs {
		font-size: 18px;

		h3 {
			font-size: 20px; } } }
