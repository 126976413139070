html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, code, em, img, strike, strong, sub, sup,
fieldset, figcaption, figure, form, label, q,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, figure, figcaption, footer, header,
menu, nav, section, summary, audio, video {
	margin:	0;
	padding: 0;
	border: 0;
	font: inherit;
	font-size: 100%;
	vertical-align: baseline;
	box-sizing: border-box;
	outline: none; }

body {
	padding: 0;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
	text-size-adjust: none; }

blockquote,	q {
	quotes:	none; }

a {
	text-decoration: none;
	color: inherit;

	img {
		border:	0; } }

p {
	margin: 0 0 20px 0; }

strong, b {
	font-weight: bold; }

hr {
	clear: both;
	height: 0;
	border: solid #ccc;
	border-width: 0 0 1px 0;
	padding-top: 10px;
	margin-bottom: 10px; }

input, textarea, button {
	padding: 0;
	border:	0;
	background:	none;
	font:	inherit;
	letter-spacing:	inherit;
	box-sizing: border-box;
	outline: none; }

table,	th,	tbody,	tr,	td {
	border:	0; }

table {
	margin-bottom: 20px;
	clear: both;
	border-spacing: 0;
	line-height: 1.2em;

	td {
		padding: 5px 10px; } }

inside,	banner,	column,	figcaption,	figure {
	display: block; }

clear {
	display: block;
	clear: both; }

.alignright, .alignleft, .alignmiddle, .alignnone {
	width: 100%;
	height: auto;
	float: none;
	margin: 0 0 20px 0;
	display: block; }
