
@media print {

	@page {
		size: 8.5in 11in; }

	@page:left {
		@bottom-right {
			margin: 10pt 0 30pt 0;
			border-top: .25pt solid #666;
			content: "Our Cats";
			font-size: 9pt;
			color: #333; }

		@bottom-left {
			margin: 10pt 0 30pt 0;
			border-top: .25pt solid #666;
			content: counter(page);
			font-size: 9pt; } }


	body {
		font-size: 10pt; }

	#bigMenu, header, .sidebar, .printPageLink, footer .links, #backToTop {
		display: none; }

	footer main {
		padding: 10px;
		text-align: center;
		justify-content: center; }

	.photo-gallery {

		.gallery-thumb {
			page-break-before: always;
			page-break-after: always;
			height: 90vh;
			width: 100%;

			.image {
				border-width: 0;
				box-shadow: none;
				width: 100%;
				height: 80% !important;

				img {
					&:last-child {
						display: block; }
					&:first-child {
						display: none; } } } } } }

