.pi-pushmenu {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;

  &.on {
    opacity: 1; }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); }

  .sled {
    position: absolute;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #ddd;
    overflow: auto;
    transition: width 0.3s; }

  &.on .sled {
    width: 320px; }

  .top-bar {
    height: 60px;
    line-height: 60px;
    background-color: #444; }

  .pi-modal-close-button {
    position: absolute;
    top: 15px;
    right: 15px; }

  li {
    position: relative;
    display: block;
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #666;

    a {
      display: inline-block;
      width: 100%;
      height: 44px;
      line-height: 44px;
      padding: 0 20px; }

    &:hover {
      background-color: #f2f2f2; } } }
