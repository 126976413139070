.pi-crossfader {
	background-color: #ccc;
	height: 60vh;

	.item, .image-wrapper, .image-wrapper img {
		max-width: 100%;
		max-height: 100%; }

	.item {
		display: block;
		opacity: 0;
		transition: opacity 0.3s;

		&.on {
			opacity: 1; } }

	.pager {
		bottom: 5%; } }
