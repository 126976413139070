
@font-face {
	font-family: 'DIN';
	src: url('../fonts/DIN-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/DIN-Light.otf')  format('opentype'), url('../fonts/DIN-Light.woff') format('woff'), url('../fonts/DIN-Light.ttf')  format('truetype'), url('../fonts/DIN-Light.svg#DIN-Light') format('svg');
	font-weight: 100;
	font-style: normal; }

@font-face {
	font-family: 'DIN';
	src: url('../fonts/DIN-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/DIN-Regular.woff') format('woff'), url('../fonts/DIN-Regular.ttf')  format('truetype'), url('../fonts/DIN-Regular.svg#DIN-Regular') format('svg');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'DIN';
	src: url('../fonts/DIN-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/DIN-Medium.otf')  format('opentype'), url('../fonts/DIN-Medium.woff') format('woff'), url('../fonts/DIN-Medium.ttf')  format('truetype'), url('../fonts/DIN-Medium.svg#DIN-Medium') format('svg');
	font-weight: 600;
	font-style: normal; }

@font-face {
	font-family: 'DIN';
	src: url('../fonts/DIN-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/DIN-Bold.otf')  format('opentype'), url('../fonts/DIN-Bold.woff') format('woff'), url('../fonts/DIN-Bold.ttf')  format('truetype'), url('../fonts/DIN-Bold.svg#DIN-Bold') format('svg');
	font-weight: bold;
	font-style: normal; }

@font-face {
	font-family: 'DIN';
	src: url('../fonts/DIN-Black.eot?#iefix') format('embedded-opentype'),  url('../fonts/DIN-Black.otf')  format('opentype'), url('../fonts/DIN-Black.woff') format('woff'), url('../fonts/DIN-Black.ttf')  format('truetype'), url('../fonts/DIN-Black.svg#DIN-Black') format('svg');
	font-weight: 900;
	font-style: normal; }








$baseFont: 'Proxima Nova', Arial, Helvetica, sans-serif;

$heading: 'DIN', Arial, Helvetica, sans-serif;

$main-nav-font: 'Roboto-Black';
