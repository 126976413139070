@import "../partials/colors";

// blips a-munga!!!!!!!
$center-transform: translate(-50%, -50%);
$hot-spot-border: 2px solid $deep-blue;


html, body {
	font-size: 16px; }

input[type=submit], button, .button {
	color: #ffffff;
	background: $deep-blue;
	border: none;
	font-weight: 600;
	cursor: pointer; }

table {
	tr {
		td {
			padding: 10px 10px 10px 0;
			border: solid #ccc;
			border-width: 1px 0 0 0; }

		&:last-child {
			td {
				border-width: 1px 0; } } } }

.tableBox {
	width: 100%;
	overflow: auto; }


section {
	position: relative;
	padding: 0 20px; }

main {
	width: 100%;
	max-width: 1180px;
	text-align: left;
	margin: 0 auto;
	padding: 20px 0;
	position: relative; }

header {
	position: relative;
	width: 100%;
	height: 40px;
	text-align: center;
	z-index: 1000;
	background: $deep-blue;

	main {
		max-width: 1280px;
		padding: 0; }

	.bottom {
		background-color: #035a9d;
		height: 70px; }

	.top, .bottom {
		position: relative;
		z-index: 1001; } }



#logo {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	position: absolute;
	top: 0;
	left: 10px;
	color: #fff;
	text-align: left;
	font-family: 'DIN', Helvetica, Arial, sans-serif;
	font-weight: 800;
	text-transform: uppercase;
	line-height: 1.2em;

	img {
		height: calc(100% - 10px); }

	span {
		display: none; } }


.searchform {
	overflow: hidden;
	position: relative;
	display: inline-block;
	padding: 0;
	width: 100%;

	input[type=text] {
		border-width: 0;
		float: left;
		color: #000;
		width: 100%;
		height: 50px;
		background: #fff;
		padding: 2px 50px 0 15px;
		background-color: rgba(255,255,255,0.7);
		transition: all 0.2s ease-out;
		font-size: 15.5px;
		font-weight: 200;
		border-radius: 0 !important;

		&:focus {
			background: #fff; } }

	button {
		display: block;
		float: left;
		border-width: 0;
		cursor: pointer;
		width: 50px;
		height: 50px;
		margin-left: -50px;
		background: url(../images/searchIcon.svg) center center no-repeat;
		background-size: 60%;
		opacity: 0.5;

		&.focus {
			opacity: 1; } } }

#searchPageForm {
	input[type=text] {
		background: #eee;
		border: 1px solid #aaa;

		&:focus {
			background: #fff;
			border-color: #888; } } }


// SEARCH BOX IN THE BIG MENU
#searchformNav {
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	display: flex;
	justify-content: center;

	input[type=text] {
		max-width: 500px; } }

// SEARCH INTRO BOX
#searchIntro {
	position: relative;
	z-index: 3;
	color: #fff;
	text-align: center;
	font-size: 18px;
	line-height: 1.4em;
	//width: calc(100% - 40px)
	width: 100%;
	max-width: 480px;
	padding: 20px;
	margin: 0 auto;

	h1 {
		font-weight: 900;
		margin-bottom: 0;
		letter-spacing: -0.05em;
		line-height: 1.1em;
		font-size: 45px;

		b {
			//font-size: 85px
			font-size: 67px;
			display: block;
			font-weight: 900; } }

	p {
		font-size: 17px;
		line-height: 1.5em;
		display: none;
		text-shadow: 0 0 10px black; }

	.searchform {
		height: 100%;
		padding: 15px 0 0 0;
		margin: 20px 0 15px 0;

		& * {
			vertical-align: middle; } } }




paging {
	display: block;
	text-align: center;
	padding: 5px 0;

	& > * {
		display: inline-block;
		padding: 0 5px;

		&.current {
			background: #eee; } } }









#mobileIntro main {
	text-align: center; }

.autocomplete-suggestions {
	background: #FFF;
	cursor: default;
	overflow: visible;
	box-shadow: 4px 4px 0 0 rgba(0,0,0,0.2);
	margin-top: 2px; }

.autocomplete-suggestion {
	padding: 2px 15px;
	line-height: 30px;
	white-space: nowrap;
	overflow: hidden;
	color: #888;
	transition: all 0.15s ease-out;
	width: calc(100% + 2px);
	background: #fff;

	strong {
		color: $deep-blue; } }

.autocomplete-no-suggestion {
	padding: 2px 5px; }

.autocomplete-selected {
	background: #eee;
	color: #444; }

.autocomplete-group {
	padding: 2px 5px; }

.autocomplete-group strong {
	font-weight: bold;
	font-size: 16px;
	color: #000;
	display: block;
	border-bottom: 1px solid #000; }




// MENU BUTTON / HAMBURGER
.menuButton {
	padding: 0;
	color: #fff;
	display: block;
	height: 100%;
	width: 50px;
	float: left;
	text-transform: uppercase;
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer; }

.menuButton span {
	display: block;
	line-height: 20px;
	height: 20px;
	width: 100%;
	font-size: 14px;
	font-weight: 400;
	position: absolute;
	bottom: -20px;
	left: 0;
	text-align: center; }

.hamburger {
	display: block;
	height: 4px;
	width: 30px;
	background-color: #fff;
	vertical-align: middle;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%); }

.hamburger:before, .hamburger:after {
	content: '';
	display: block;
	position: absolute;
	height: 4px;
	width: 100%;
	background-color: #fff;
	right: 0;
	top: -10px; }

.hamburger:after {
	top: auto;
	bottom: -10px; }



body.openNav {

	.menuButton {
		opacity: 1 !important;
		color: #fff !important;

		.hamburger {
			background-color: transparent !important; } }

	&:hover .hamburger:before, .hamburger:before {
		width: 100%;
		top: 0;
		right: 0;
		transform: rotate(45deg);
		background-color: #fff !important; }

	&:hover .hamburger:after, .hamburger:after {
		width: 100%;
		bottom: 0;
		right: 0;
		transform: rotate(-45deg);
		background-color: #fff !important; } }



/////////////////////////////////
// NEW BIG MENU
/////////////////////////////////
.bigMenu {
	position: absolute;
	top: 40px;
	left: 0;
	width: 100%;
	height: calc(100vh - 40px);
	z-index: 1002;
	overflow: auto;
	text-align: left;
	background: $deep-blue;
	color: #fff;
	padding: 20px;
	opacity: 0;
	visibility: hidden;
	transform: translateY(5%);
	transition: all 0.2s ease-out, visibility 0s 0.2s;

	ul, li {
		padding: 0; }

	h2 {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 10px;
		line-height: 1.5em;
		text-transform: uppercase; }

	.content {
		float: none;
		width: auto;
		margin-bottom: 10px;
		line-height: 1.5em;

		.menu {
			column-count: 1; } }

	.menuBoxSidebar {
		width: auto;
		float: none;
		padding: 20px 0 0 0;
		border-top: 1px solid rgba(255,255,255,0.5); }

	// NAVIGATION
	.menu {
		width: auto;
		margin: 0 -10px;

		ul, li, a {
			display: block;
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid; }

		& > li {
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid;
			padding: 0 10px;

			&.current_page_ancestor, &.current_page_item {
				background: rgba(0,0,0,0.1);

				.current_page_item a {
					color: #fff; } }

			& > a {
				font-size: 20px;
				line-height: 22px;
				margin-bottom: 5px;
				padding: 4px 10px 4px 0;
				color: #fff; }

			& .children {

				li {
					margin: 0 0 8px 0;
					-webkit-column-break-inside: avoid;
					page-break-inside: avoid;
					break-inside: avoid;
					display: inline-block;
					width: 100%; }

				a {
					color: rgba(255,255,255,0.6);
					line-height: 1.2em;

					&:hover {
						color: #fff; } }

				.children {
					padding: 5px 0 0 10px;
					margin: 3px;
					border-left: 1px solid rgba(255,255,255,0.5); } } } }


	.menu > li > .children > li:last-child {
		padding-bottom: 10px;
		display: inline-block; } }


body.openNav {
	overflow: hidden;

	#menuBox {
		opacity: 1;
		visibility: visible;
		transition: all 0.2s ease-out, visibility 0s;
		transform: translateY(0); } }



///////////////////////////////
// HERO IMAGE / HOTSPOT IMAGE
///////////////////////////////
body.home #hero {
	display: none;
	line-height: 0;
	padding: 0;
	margin-bottom: 0; }

body.inside-page .hero-image {
	width: 100%; }

#hero {
	position: relative;
	margin-bottom: 20px;

	.hot-spot {
		position: absolute;
		z-index: 0;

		.flyout {
			display: none; }

		.blip {
			position: absolute;
			width: 7vw;
			height: 7vw;
			line-height: 6.5vw;
			font-size: 5vw;
			background-color: rgba(38, 47, 53, 0.6);
			box-shadow: 0 0 0 3px rgba(255,255,255,0.3);
			border-width: 0;
			border-radius: 50%;
			cursor: pointer;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			transition: background-color 0.3s;
			text-align: center;
			color: #fff; } } }

body.home #hero .blip {
	color: transparent !important; }

#hotspot-mobile-list {
	margin: 0;
	padding: 0;

	li {
		padding-left: 45px;
		margin-bottom: 20px;
		list-style: none;
		counter-increment: step-counter;

		&:before {
			content: counter(step-counter);
			width: 30px;
			height: 30px;
			line-height: 30px;
			font-size: 18px;
			background-color: rgba(216, 242, 251, 0.9);
			border: 2px solid #035a9d;
			border-radius: 50%;
			cursor: pointer;
			text-align: center;
			color: #035a9d;
			position: absolute;
			top: -4px;
			left: -5px; }

		h3 {
			margin-bottom: 5px; } } }


body.home #hero {
	height: auto;
	min-height: 280px;
	width: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	flex-direction: column;

	#homeHeroBox {
		//position: absolute
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100vh - 210px);
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover; }

	#homeHero {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;

		.hero-image {
			display: none; } }

	div.hot-spot {
		display: none; } }


#homeHeroCTAs {
	position: relative;
	width: 100%;
	color: rgba(255,255,255,0.7);
	font-size: 14px;
	line-height: 1.4em;
	overflow: hidden;

	& > * {
		padding: 15px 20px;
		margin-bottom: 1px; }

	h3 {
		font-weight: 800;
		color: #ffffff;
		margin-bottom: 0;
		font-size: 16px;
		line-height: 1.5em;
		letter-spacing: 0; }

	.caption {
		background: $dark-gray;
		text-align: center;
		display: none; }

	a {
		display: block;
		background: $dark-gray;
		padding-right: 40px;
		position: relative;

		&:hover {
			background: #1e2224; }

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			right: 15px;
			width: 15px;
			height: 15px;
			border: solid rgba(255,255,255,0.3);
			border-width: 4px 4px 0 0;
			transform: translateY(-50%) rotate(45deg); } } }








///////////////////////////////
// BREADCRUMB
///////////////////////////////
#breadcrumb {
	margin-bottom: 10px;
	position: relative;

	& > div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: 20px; }

	a {
		margin-right: 20px;
		position: relative;
		display: inline-block;

		&:after {
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			border: solid #888;
			border-width: 1px 1px 0 0;
			position: absolute;
			right: -10px;
			top: 45%;
			transform: rotate(45deg) translateY(-50%); } } }

#subMenuButton {
	display: block !important;
	position: absolute !important;
	margin: 0 !important;
	right: -10px;
	top: 50%;
	width: 30px;
	height: 30px;
	transform: translateY(-50%);
	cursor: pointer;

	&:after {
		display: none !important; }

	&:before {
		content: '';
		display: block;
		width: 35%;
		height: 35%;
		border: solid $deep-blue;
		border-width: 0 0 4px 4px;
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg); } }




// MOBILE SUBMENU
#mobileSubMenu {
	background-color: $deep-blue;
	margin: 0 -20px 20px -20px;
	padding: 10px 0;
	display: none;

	&.show {
		display: block; }

	h3 a {
		color: #fff;
		padding: 0 20px;
		display: inline-block; }

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			padding: 0;
			margin: 0 !important;
			list-style: none;

			&.current_page_item a {
				background-color: rgba(0, 0, 0, 0.1);
				color: #fff; }

			a {
				display: block;
				width: 100%;
				padding: 5px 20px;
				line-height: 24px;
				color: rgba(255, 255, 255, 0.6);

				&:hover {
					text-decoration: none;
					color: #fff; } } } } }





#pageAnchors {
	display: block;
	margin: 0 0 20px 0;
	padding: 0 0 20px 0;
	border-bottom: 1px solid #ccc;

	li {
		display: inline-block;
		position: relative;
		list-style: none;
		margin: 0;
		padding: 0;

		&:after {
			content: '|';
			margin: 0 5px; }

		&:last-child:after {
			display: none; } } }

.mainContent {
	position: relative;
	word-wrap: break-word;

	main {

		.pageTitle {
			border-bottom: 1px solid #ccc;
			padding-bottom: 10px;

			&.hasMenu {
				padding-right: 20px; } }

		.manualMeta {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
			margin-top: -10px;
			font-size: 14px;
			line-height: 40px;

			.lastUpdated {
				font-style: italic;
				color: #888; }

			.printPageLink {
				text-transform: uppercase;
				padding-left: 20px;
				background: url(../images/print-icon.png) left center no-repeat; } } }


	ul {
		margin: 0 0 20px 0;

		& > li {
			//padding-left: 25px
			margin: 0 0 10px 0;
			position: relative;
			line-height: 1.5em;

			//&:before
			//	content: ''
			//	display: block
			//	width: 6px
			//	height: 6px
			//	background-color: $deep-blue
			//	position: absolute
			//	left: 10px
			//	top: 9px
 } }			//

	ol {

		& > li {
			//counter-increment: step-counter
			//padding-left: 25px
			margin: 0 0 10px 0;
			position: relative;
			line-height: 1.5em;

			//&:before
			//	content: counter(step-counter) '.'
			//	color: $deep-blue
			//	position: absolute
			//	left: 0
			//	top: 0
			//	font-weight: bold
			//	display: block
			//	width: 22px
 } } }			//	text-align: right


.simpleTagList {
	font-style: italic;
	font-size: 14px;
	line-height: 1.5em;
	padding: 5px;
	margin: 5px 0;
	background: #eee; }



.tagList {
	//border: 1px solid #ddd
	//border-width: 1px 0
	//padding: 5px 0 3px 0
	//margin: 0 0 20px 0

	h3 {
		//display: inline-block
		//float: left
		//width: 50px
		//line-height: 26px
 }		//margin: 0

	.tags {
		line-height: 0;
		//float: right
 }		//width: calc(100% - 50px)

	a {
		display: inline-block;
		padding: 0 7px;
		font-size: 14px;
		line-height: 24px;
		color: #444444;
		background: #e5e5e5;
		margin: 4px 4px 0 0;
		font-weight: normal;
		transition: all 0.15s ease-out;

		&:hover {
			text-decoration: none;
			background: $deep-blue;
			color: #fff; } } }






// sidebar
.sidebar {

	ul li {
		padding: 0;
		margin: 0;
		list-style: none;

		&:before {
			display: none; }

		&.current_page_item {
			a {
				color: #000; } } }

	&.childPageList {
		display: none; }

	&.tagList {
		margin-top: 20px; } }

///////////////////////////////
// FOOTER
///////////////////////////////
footer {
	padding: 0 30px;
	background: #eee;

	a {
		font-weight: 600;
		color: $deep-blue;

		&:hover {
			text-decoration: underline; } }

	main {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		padding: 20px 0;
		font-size: 14px;

		.links {
			ul {
				display: block;
				overflow: hidden;
				margin: 5px 0;
				padding: 0;
				text-align: center;
				line-height: 1.5em;

				li {
					display: inline-block;
					padding: 0 5px;

					&:last-child {
						margin-right: 0;
						border-right: none; } } } } } }


#legal {
	padding: 0 30px;
	background: #555;
	color: #fff;
	font-size: 12px;

	main {
		padding: 5px 0; }

	.links {
		float: right;

		ul, li, a {
			list-style: none;
			display: inline-block;
			line-height: 12px; }

		li {
			padding: 0 10px;
			border-left: 1px solid #fff;

			&:first-child {
				border-width: 0; } }

		a:hover {
			text-decoration: underline; } } }






/* *///////////////////////////////////////
// PHOTO/VIDEO GALLERY
//////////////////////////////////////*/
.photo-gallery {
	padding: 20px 0 10px 0;
	border: solid #ccc;
	border-width: 0;
	margin-bottom: 20px;

	.gallery-thumb {
		width: auto;
		margin: 0 0 20px 0;
		position: relative;
		overflow: hidden;
		display: block;

		.image {
			width: 100%;
			height: 50vw;
			position: relative;
			background-size: 90%;
			background-position: center center;
			background-repeat: no-repeat;
			border: 1px solid #ccc;
			overflow: hidden;

			img {
				max-height: calc(100% - 20px);
				max-width: calc(100% - 20px);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				&:last-child {
					display: none; } }

			&:after, &:before {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.5) url(/wp-content/themes/uc/images/zoomIcon.svg) center center no-repeat;
				background-size: 40px;
				transition: all 0.2s ease-out;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2; }

			&:before {
				width: 30px;
				height: 30px;
				background-color: rgba(0,0,0,0.5);
				background-size: 60%;
				top: auto;
				bottom: 0;
				opacity: 1; } }

		.caption {
			padding: 10px 5px 0 5px;
			text-align: center;
			line-height: 1.2em;
			font-weight: normal;
			font-style: italic; }

		&:hover {
			text-decoration: none;

			.image {
				&:after {
					opacity: 1; }
				&:before {
					opacity: 0; } } } } }



///////////////////////////////
// FANCYBOX STYLES
///////////////////////////////
#fancybox-overlay {
	background-color: #000 !important; }

#fancybox-close {
	position: fixed;
	top: 0;
	right: 0;
	background-image: none;
	cursor: pointer;
	height: 60px;
	width: 60px;

	&:hover {
		opacity: 1; }

	&:before, &:after {
		content: '';
		height: 60%;
		width: 4px;
		position: absolute;
		top: 50%;
		left: 50%;
		background-color: #fff;
		transform: translate(-50%, -50%) rotate(45deg); }

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg); } }

#fancybox-left, #fancybox-right {
	position: fixed;
	width: 50%;
	visibility: visible;
	display: block;

	&:hover {
		opacity: 1; }

	span {
		display: none; }

	&:after {
		content: '';
		width: 30px;
		height: 30px;
		display: block;
		border: solid #fff;
		border-width: 4px 4px 0 0;
		transform: translateY(-50%) rotate(45deg);
		position: absolute;
		top: 50%;
		right: 40px; } }

#fancybox-left:after {
	transform: translateY(-50%) rotate(-135deg);
	right: auto;
	left: 40px; }

.fancybox-bg {
	display: none; }





///////////////////////////////
// BACK TO TOP BUTTON
///////////////////////////////
#backToTop {
	display: block;
	position: fixed;
	right: 20px;
	bottom: 20px;
	width: 50px;
	height: 50px;
	background: rgba(0,0,0,0.5);
	border-radius: 0;
	cursor: pointer;
	transition: all 0.2s ease-out, visibility 0s 0.2s;
	opacity: 0;
	visibility: hidden;

	&:hover {
		background: #000; }

	&:before {
		content: '';
		display: block;
		width: 35%;
		height: 35%;
		border: solid #ffffff;
		border-width: 4px 4px 0 0;
		position: absolute;
		top: 57%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg); }

	&.show {
		transition: all 0.2s ease-out, visibility 0s 0s;
		opacity: 1;
		visibility: visible; } }





///////////////////////////////
// ARCHIVE LIST / TAGS
///////////////////////////////
.archiveList {
	article {
		padding: 10px 0;
		border-bottom: 1px solid #cccccc;

		&:first-child {
			border-top: 1px solid #cccccc; }

		h2 {
			margin-bottom: 0; } } }



///////////////////////////////
// SITEMAP
///////////////////////////////
#sitemap {
	margin: 0;
	padding: 0;
	display: block;
	position: relative;

	li, ul, div, a {
		margin: 0;
		padding: 0;
		display: block;
		position: relative; }

	li {
		&:before {
			display: none; }

		& > input[type="checkbox"] {
			position: absolute;
			top: 7px;
			left: 5px; } }

	a {
		display: inline;

		&:before {
			content: '—';
			color: #888;
			position: absolute;
			top: 7px;
			left: -20px;
			line-height: 0;
			font-weight: 200; } }

	div {
		padding-top: 5px;
		padding-bottom: 5px;

		&.selected {
			background: #eff8ff; } }

	& > li > div {
		background: #f5f5f5;

		& > a:before {
			display: none; } }

	li div {
		padding-left: 30px; }
	li li div {
		padding-left: 50px; }
	li li li div {
		padding-left: 70px; }
	li li li li div {
		padding-left: 90px; } }


#pdfActionBar {
	background: #e5e5e5;
	border-top: 1px solid #ccc;

	&.stuck {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: 3;
		transition: box-shadow 0.2s; }

	main {
		padding: 10px 0;
		display: flex;
		justify-content: center;
		align-items: center;

		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			left: 5px;
			width: 0;
			height: 0;
			bottom: 100%;
			border-style: solid;
			border-width: 0 11px 11px 11px;
			border-color: transparent transparent #aaa transparent; }

		&:after {
			left: 6px;
			border-width: 0 10px 10px 10px;
			border-color: transparent transparent #e5e5e5 transparent; }

		& > div {
			padding: 0 0 0 5px;
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			position: relative; } }

	.step2 {
		display: none; }
	.step2 span {
		font-style: italic;
		line-height: 1.4em; }
	span {
		display: inline-block; }

	button.cancel {
		background: #666; } }

#pdfLinkBox {
	display: none;
	padding: 10px;
	text-align: center;
	background: yellow; }

#printBox {
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100vh - 70px);
	background: #fff;
	display: none;

	main {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 0; }

	iframe {
		border: 1px solid #666;
		width: 100%;
		height: calc(100% - 60px); } }

body.printBoxOpen {
	overflow: hidden; }

body.printBoxOpen #printBox {
	display: block; }
body.printBoxOpen #pdfActionBar .step2 {
	display: flex; }
body.printBoxOpen #pdfActionBar .step1 {
	display: none; }












@media screen and (min-width: 400px) {
	#searchIntro {
		h1 {
			font-size: 57px;
			b {
				font-size: 85px; } } } }



@media screen and (min-width: 550px) {

	.bigMenu .content .menu {
		column-count: 2; } }



@media screen and (min-width: 600px) {

	#homeHeroCTAs {
		display: flex;
		justify-content: space-between;
		align-items: stretch;

		a {
			width: 50%; } } }



@media screen and (max-width: 767px) and (orientation: landscape) {

	body.home #hero #homeHeroBox {
		height: calc(100vh - 40px); } }
