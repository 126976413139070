.pi-accordion {
	.container {
		padding: 30px; }

	.item {
		border: 1px solid black;
		overflow: hidden; }

	.title {
		padding: 30px;
		background-color: #eee;
		cursor: pointer;
		transition: 0.3s; }

	.title:hover {
		background-color: #00ff99;
		color: #333; }

	.wrapper {
		position: relative;
		width: 100%;
		transition: 0.3s; }

	.content {
		padding: 60px;
		opacity: 0;
		transition: 0.3s; }

	.item.on > .wrapper > .content {
		opacity: 1; } }

