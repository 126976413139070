.pi-dialog {

	.dialog-box {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); }

	.title {
		font-size: 36px;
		text-align: center;
		padding: 30px 0; }

	.content-box {
		color: #222;
		max-width: 600px;
		overflow: hidden;

		.content {
			padding: 30px;
			border-radius: 4px;
			background-color: white; }

		.buttons {
			border-top: 1px solid black;
			text-align: right;
			margin-top: 30px;

			button {
				color: $awesome-green;
				border-color: $awesome-green;
				border-radius: 4px;
				transition: 0.3s;

				&:hover {
					background-color: $awesome-green;
					color: black; } } } } }
