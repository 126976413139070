
@media screen and (max-width: 1280px) {

	header .top {
		padding: 0 20px; } }




@media screen and (min-width: 768px) {

	body {
		padding-top: 60px; }


	h1 {
		font-size: 2.5em; }

	h2 {
		font-size: 2em; }

	h3 {
		font-size: 1.5em; }

	h4 {
		font-size: 1.2em; }

	h5 {
		font-size: 1.2em; }

	section {
		padding: 0 30px; }

	main {
		padding: 30px 0; }

	header {
		position: fixed;
		top: 0;
		left: 0;
		height: 60px; }

	#logo {
		img {
			height: calc(100% - 15px);
			margin-right: 10px; }
		span {
			display: block; } }

	.menuButton {
		width: 60px; }

	.bigMenu .content .menu {
		column-count: 3; }


	// NAVIGATION
	.bigMenu {
		top: 60px;
		height: calc(100vh - 60px);
		padding: 20px;
		position: fixed; }

	#navBox > li > .children {
		column-count: 2; }

	#mobileSubMenu {
		margin: 0 -30px 20px -30px;

		h3 a {
			padding: 0 30px; }

		ul li a {
			padding: 5px 30px; } }





	#hero {
		display: block;
		margin-bottom: 20px;

		.hot-spot {
			position: absolute;
			z-index: 0;
			display: block;

			&:hover .blip, &.on .blip {
				background-color: $deep-blue !important;
				color: $pale-translucent-blue; }

			&:hover .blip:before, &:hover .blip:after, &.on .blip:after {
				background-color: $pale-translucent-blue; }

			&.on {
				z-index: 1001; }

			.blip {
				width: 40px;
				height: 40px;
				font-size: 18px;
				line-height: 40px;

				&:after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 50%;
					background-color: #fff;
					transition: 0.3s;
					animation: blipFlash 1s infinite; } }

			.flyout {
				display: block;
				position: absolute;
				bottom: calc(50% + 10px);
				left: 50%;
				width: 0;
				background-color: white;
				border: $hot-spot-border;
				overflow: hidden;
				opacity: 0;
				transition: opacity 0.3s, width 0.3s, margin 0.3s;
				transform: translateX(-50%);
				line-height: 1.3em;
				color: #000;
				margin-bottom: 0;
				font-weight: 400;

				h5 {
					margin-bottom: 10px; }

				.wrapper {
					position: absolute;
					bottom: 0;
					left: 0;
					min-height: 100%;
					width: 300px;
					padding: 15px;

					&:hover {

						.see-more {
							background-color: $pale-translucent-blue; } } }

				.see-more {
					position: absolute;
					top: 0;
					right: 0;
					width: 50px;
					height: 100%;
					background: none;
					border: 0;
					border-left: 1px solid rgba(0,0,0,0.1);
					transition: background-color 0.15s ease-out;
					display: none;

					&:before {
						content: "";
						position: absolute;
						top: calc(50% + 2px);
						left: calc(50% - 5px);
						transform: $center-transform rotate(45deg);
						width: 12px;
						height: 12px;
						border-top: $hot-spot-border;
						border-right: $hot-spot-border;
						transition: border-color 0.15s ease-out; } } }

			&.on .flyout {
				opacity: 1; }

			&.right {
				.flyout {
					left: auto;
 }					//right: 50%

				.wrapper {
					left: auto; } }

			&.right.on	.blip:after {
				transform: $center-transform rotate(-180deg); } } }



	body.openFlyout {
		.blip:after {
			display: none; }

		#hero .hot-spot .blip {
			background: transparent;
			box-shadow: 0 0 0 3px rgba(255,255,255,0.7); } }

	@keyframes blipFlash {
		from {
			opacity: 0.3; }
		to {
			opacity: 0; } }



	// blips
	#hero {
		position: relative;
		display: block;
		width: 100%;
		background-size: cover;
		background-position: center center; }

	body.inside-page #hero {
		margin-top: 0; }

	body.home #hero {
		height: calc(100vh - 60px);
		margin-bottom: 0;

		#homeHeroBox {
			position: absolute;
			height: 100%; }

		#homeHero {
			height: 100%;

			.hot-spot {
				display: none;

				.flyout {
					border-radius: 0px !important;
					border: none !important;
					color: #fff !important;
					background: $dark-gray;
					box-shadow: 0 0 0 3px rgba(255,255,255,0.3);
					//transition: background-color 0.15s ease-out

					&:hover {
						background: $deep-blue !important; }

					h5 {
						color: #ffffff;
						font-size: 18px;
						font-weight: 900;
						margin-bottom: 5px; } } } } }

	#hotspot-mobile-list {
		display: none; }

	#homeHeroCTAs {
		width: 100%;
		display: flex;
		justify-content: space-between;
		color: rgba(255,255,255,0.7);
		font-size: 14px;
		overflow: visible;
		position: absolute;
		bottom: 0;
		left: 0;

		& > * {
			margin-bottom: 0; }

		h3 {
			font-weight: 800;
			color: #ffffff;
			margin-bottom: 0;
			font-size: 16px;
			line-height: 1.5em;
			letter-spacing: 0; }

		.caption {
			padding: 15px 15vw;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transform: translateY(-100%);
			display: block;
			background: transparentize($dark-gray, 0.5); }

		a {
			display: block;
			width: 50%;
			float: none; } }

	#searchIntro {
		position: absolute;
		left: 50%;
		top: 20px;
		transform: translateX(-50%);

		h1 {
			font-size: 57px;

			b {
				font-size: 85px;
				display: block;
				font-weight: 900; } }

		p {
			display: block; } }


	.searchform input[type=text] {
		font-size: 20px; }


	#mobileIntro {
		display: none; }

	#breadcrumb {
		b, strong {
			display: inline-block; } }


	footer {
		main {
			flex-direction: row;
			justify-content: space-between; } }



	/* *///////////////////////////////////////
	// PHOTO/VIDEO GALLERY
	//////////////////////////////////////*/
	.photo-gallery {

		.gallery-thumb {
			float: left;
			width: calc(50% - 10px);
			margin: 0 20px 20px 0;

			&:nth-child(2n) {
				margin-right: 0; }

			&:nth-child(2n+1) {
				clear: both; }

			.image {
				width: 100%;
				height: 240px; } } }

	#printBox {
		top: 50%;
		height: calc(100vh - 110px);
		transform: translateY(-50%); } }




@media screen and (min-width: 900px) {

	.alignleft, .alignright, .aligncenter, .alignnone {
		margin: 5px 0 20px 0;
		width: auto;
		height: auto;
		max-width: 50%;
		display: block;

		img {
			width: 100%;
			height: auto; } }

	.alignleft {
		float: left;
		margin-right: 20px; }

	.alignright {
		float: right;
		margin-left: 20px; }

	.aligncenter {
		margin: 5px auto 20px auto;
		text-align: center;

		img {
			margin: 0 auto; } }


	.bigMenu .content .menu {
		column-count: 4; }

	body.home #hero {
		height: calc(80vw - 60px); } }

















@media screen and (min-width: 1024px) {

	#hero {
		margin-top: 0 !important; }

	body.home #hero {
		height: calc(100vh - 60px); }

	// NAVIGATION
	#navBox {

		& > li > a {
			padding: 20px; }

		& > li > .children {
			column-count: 3; } }

	#main {
		text-align: left; }

	.the_content_wrapper {
		position: relative;
		float: left;
		width: calc(75% - 30px); }

	.sidebar {
		position: relative;
		float: right;
		width: 25%;
		margin-bottom: 20px;

		&.tagList {
			margin-top: 0; } }

	a.arrow {
		font-weight: 600;
		color: $deep-blue;
		line-height: 36px;

		&:hover {
			text-decoration: none; }

		&:after {
			content: "";
			display: inline-block;
			width: 6px;
			height: 6px;
			border-top: $hot-spot-border;
			border-right: $hot-spot-border;
			transform: rotate(45deg);
			margin-left: 10px;
			vertical-align: middle; } } }


@media screen and (max-width: 1024px) {

	body {
		padding-bottom: 0 !important; }

	#backToTop {
		bottom: 0 !important;
		right: 0;
		width: 40px;
		height: 40px; }

	.printPageLink {
		display: none; }

	#sitemap {
		li {
			& > input[type="checkbox"] {
				display: none; } }

		div.selected {
			background: inherit !important; } }

	#pdfActionBar {
		display: none; } }
